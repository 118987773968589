/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/cash-dom@2.3.9/dist/cash.min.js
 * - /gh/spruijtdesign/cashallax@master/cashallax.min.js
 * - /gh/spruijtdesign/revel@master/revel.min.js
 * - /gh/spruijtdesign/cashbox@1.0.0/cashbox.min.js
 * - /npm/lazysizes@4.1.5/lazysizes.min.js
 * - /npm/lazysizes@4.1.5/plugins/respimg/ls.respimg.min.js
 * - /npm/lazysizes@4.1.5/plugins/bgset/ls.bgset.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
