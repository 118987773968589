/* MIT https://github.com/kenwheeler/cash */
(function(){
'use strict';var e=document,g=window,h=Array.prototype,l=h.filter,aa=h.indexOf,ba=h.map,m=h.push,n=h.reverse,q=h.slice,ca=/^#[\w-]*$/,da=/^\.[\w-]*$/,ea=/<.+>/,fa=/^\w+$/;function r(a,b){void 0===b&&(b=e);return da.test(a)?b.getElementsByClassName(a.slice(1)):fa.test(a)?b.getElementsByTagName(a):b.querySelectorAll(a)}
function t(a,b){void 0===b&&(b=e);if(a){if(a.__cash)return a;var c=a;if(u(a)){if(b.__cash&&(b=b[0]),c=ca.test(a)?b.getElementById(a.slice(1)):ea.test(a)?v(a):r(a,b),!c)return}else if(w(a))return this.ready(a);if(c.nodeType||c===g)c=[c];this.length=c.length;a=0;for(b=this.length;a<b;a++)this[a]=c[a]}}function x(a,b){return new t(a,b)}var y=x.fn=x.prototype=t.prototype={constructor:x,__cash:!0,length:0,splice:h.splice};y.get=function(a){return void 0===a?q.call(this):this[0>a?a+this.length:a]};
y.eq=function(a){return x(this.get(a))};y.first=function(){return this.eq(0)};y.last=function(){return this.eq(-1)};y.map=function(a){return x(ba.call(this,function(b,c){return a.call(b,c,b)}))};y.slice=function(){return x(q.apply(this,arguments))};var ha=/(?:^\w|[A-Z]|\b\w)/g,ia=/[\s-_]+/g;function z(a){return a.replace(ha,function(a,c){return a[c?"toUpperCase":"toLowerCase"]()}).replace(ia,"")}x.camelCase=z;function A(a,b){for(var c=0,d=a.length;c<d&&!1!==b.call(a[c],a[c],c,a);c++);}x.each=A;
y.each=function(a){A(this,function(b,c){return a.call(b,c,b)});return this};y.removeProp=function(a){return this.each(function(b,c){delete c[a]})};"undefined"!==typeof exports?module.exports=x:g.cash=g.$=x;x.extend=y.extend=function(a){void 0===a&&(a=this);for(var b=arguments,c=b.length,d=2>c?0:1;d<c;d++)for(var f in b[d])a[f]=b[d][f];return a};var B=1;x.guid=B;
function C(a,b){var c=a&&(a.matches||a.webkitMatchesSelector||a.mozMatchesSelector||a.msMatchesSelector||a.oMatchesSelector);return!!c&&c.call(a,b)}x.matches=C;function w(a){return"function"===typeof a}x.isFunction=w;function u(a){return"string"===typeof a}x.isString=u;function D(a){return!isNaN(parseFloat(a))&&isFinite(a)}x.isNumeric=D;var E=Array.isArray;x.isArray=E;
y.prop=function(a,b){if(a){if(u(a))return 2>arguments.length?this[0]&&this[0][a]:this.each(function(c,f){f[a]=b});for(var c in a)this.prop(c,a[c]);return this}};function F(a){return u(a)?function(b,c){return C(c,a)}:a.__cash?function(b,c){return a.is(c)}:function(a,c,d){return c===d}}y.filter=function(a){if(!a)return x();var b=w(a)?a:F(a);return x(l.call(this,function(c,d){return b.call(c,d,c,a)}))};var ja=/\S+/g;function G(a){return u(a)?a.match(ja)||[]:[]}
y.hasClass=function(a){var b=G(a),c=!1;b.length&&this.each(function(a,f){c=f.classList.contains(b[0]);return!c});return c};y.removeAttr=function(a){var b=G(a);return b.length?this.each(function(a,d){A(b,function(a){d.removeAttribute(a)})}):this};y.attr=function(a,b){if(a){if(u(a)){if(2>arguments.length){if(!this[0])return;var c=this[0].getAttribute(a);return null===c?void 0:c}return null===b?this.removeAttr(a):this.each(function(c,f){f.setAttribute(a,b)})}for(c in a)this.attr(c,a[c]);return this}};
y.toggleClass=function(a,b){var c=G(a),d=void 0!==b;return c.length?this.each(function(a,k){A(c,function(a){d?b?k.classList.add(a):k.classList.remove(a):k.classList.toggle(a)})}):this};y.addClass=function(a){return this.toggleClass(a,!0)};y.removeClass=function(a){return arguments.length?this.toggleClass(a,!1):this.attr("class","")};function I(a){return a.filter(function(a,c,d){return d.indexOf(a)===c})}x.unique=I;y.add=function(a,b){return x(I(this.get().concat(x(a,b).get())))};
function J(a,b,c){if(1===a.nodeType)return a=g.getComputedStyle(a,null),b?c?a.getPropertyValue(b):a[b]:a}function K(a,b){return parseInt(J(a,b),10)||0}var L=/^--/,M={},ka=e.createElement("div").style,la=["webkit","moz","ms","o"];function N(a,b){void 0===b&&(b=L.test(a));if(b)return a;if(!M[a]){b=z(a);var c=""+b.charAt(0).toUpperCase()+b.slice(1);b=(b+" "+la.join(c+" ")+c).split(" ");A(b,function(b){if(b in ka)return M[a]=b,!1})}return M[a]}x.prefixedProp=N;
var ma={animationIterationCount:!0,columnCount:!0,flexGrow:!0,flexShrink:!0,fontWeight:!0,lineHeight:!0,opacity:!0,order:!0,orphans:!0,widows:!0,zIndex:!0};function O(a,b,c){void 0===c&&(c=L.test(a));return c||ma[a]||!D(b)?b:b+"px"}y.css=function(a,b){if(u(a)){var c=L.test(a);a=N(a,c);if(2>arguments.length)return this[0]&&J(this[0],a,c);if(!a)return this;b=O(a,b,c);return this.each(function(d,k){1===k.nodeType&&(c?k.style.setProperty(a,b):k.style[a]=b)})}for(var d in a)this.css(d,a[d]);return this};
var na=/^data-(.*)/;x.hasData=function(a){return"__cashData"in a};function P(a){return a.__cashData=a.__cashData||{}}function Q(a,b){var c=P(a);if(b){if(!(b in c)&&(a=a.dataset?a.dataset[b]||a.dataset[z(b)]:x(a).attr("data-"+b),void 0!==a)){try{a=JSON.parse(a)}catch(d){}c[b]=a}return c[b]}return c}
y.data=function(a,b){var c=this;if(!a){if(!this[0])return;A(this[0].attributes,function(a){(a=a.name.match(na))&&c.data(a[1])});return Q(this[0])}if(u(a))return void 0===b?this[0]&&Q(this[0],a):this.each(function(c,d){P(d)[a]=b});for(var d in a)this.data(d,a[d]);return this};y.removeData=function(a){return this.each(function(b,c){void 0===a?delete c.__cashData:delete P(c)[a]})};
function R(a,b){return K(a,"border"+(b?"Left":"Top")+"Width")+K(a,"padding"+(b?"Left":"Top"))+K(a,"padding"+(b?"Right":"Bottom"))+K(a,"border"+(b?"Right":"Bottom")+"Width")}A(["Width","Height"],function(a){y["inner"+a]=function(){if(this[0])return this[0]===g?g["inner"+a]:this[0]["client"+a]}});
A(["width","height"],function(a,b){y[a]=function(c){if(!this[0])return void 0===c?void 0:this;if(!arguments.length)return this[0]===g?this[0][z("outer-"+a)]:this[0].getBoundingClientRect()[a]-R(this[0],!b);c=parseInt(c,10);return this.each(function(d,f){1===f.nodeType&&(d=J(f,"boxSizing"),f.style[a]=O(a,c+("border-box"===d?R(f,!b):0)))})}});
A(["Width","Height"],function(a,b){y["outer"+a]=function(c){if(this[0])return this[0]===g?g["outer"+a]:this[0]["offset"+a]+(c?K(this[0],"margin"+(b?"Top":"Left"))+K(this[0],"margin"+(b?"Bottom":"Right")):0)}});function S(a,b){for(var c=0,d=b.length;c<d;c++)if(0>a.indexOf(b[c]))return!1;return!0}function oa(a,b,c){A(a[c],function(a){b.removeEventListener(c,a[1])});delete a[c]}
function pa(a,b,c,d){d.guid=d.guid||B++;var f=a.__cashEvents=a.__cashEvents||{};f[b]=f[b]||[];f[b].push([c,d]);a.addEventListener(b,d)}function T(a){a=a.split(".");return[a[0],a.slice(1).sort()]}function U(a,b,c,d){var f=a.__cashEvents=a.__cashEvents||{};if(b){var k=f[b];k&&(d&&(d.guid=d.guid||B++),f[b]=k.filter(function(f){var k=f[0];f=f[1];if(d&&f.guid!==d.guid||!S(k,c))return!0;a.removeEventListener(b,f)}))}else if(c&&c.length)for(b in f)U(a,b,c,d);else for(b in f)oa(f,a,b)}
y.off=function(a,b){var c=this;void 0===a?this.each(function(a,b){return U(b)}):A(G(a),function(a){a=T(a);var d=a[0],k=a[1];c.each(function(a,c){return U(c,d,k,b)})});return this};
y.on=function(a,b,c,d){var f=this;if(!u(a)){for(var k in a)this.on(k,b,a[k]);return this}w(b)&&(c=b,b=!1);A(G(a),function(a){a=T(a);var k=a[0],H=a[1];f.each(function(a,f){a=function qa(a){if(!a.namespace||S(H,a.namespace.split("."))){var p=f;if(b)for(p=a.target;!C(p,b);){if(p===f)return;p=p.parentNode;if(!p)return}a.namespace=a.namespace||"";p=c.call(p,a,a.data);d&&U(f,k,H,qa);!1===p&&(a.preventDefault(),a.stopPropagation())}};a.guid=c.guid=c.guid||B++;pa(f,k,H,a)})});return this};
y.one=function(a,b,c){return this.on(a,b,c,!0)};y.ready=function(a){function b(){return a(x)}"loading"!==e.readyState?setTimeout(b):e.addEventListener("DOMContentLoaded",b);return this};y.trigger=function(a,b){var c=a;if(u(a)){var d=T(a);a=d[0];d=d[1];c=e.createEvent("HTMLEvents");c.initEvent(a,!0,!0);c.namespace=d.join(".")}c.data=b;return this.each(function(a,b){b.dispatchEvent(c)})};
function ra(a){var b=[];A(a.options,function(a){!a.selected||a.disabled||a.parentNode.disabled||b.push(a.value)});return b}var sa=/select-one/i,V=/select-multiple/i;function W(a){var b=a.type;return sa.test(b)?0>a.selectedIndex?null:a.options[a.selectedIndex].value:V.test(b)?ra(a):a.value}var ta=/%20/g,ua=/file|reset|submit|button|image/i,va=/radio|checkbox/i;
y.serialize=function(){var a="";this.each(function(b,c){A(c.elements||[c],function(b){if(!b.disabled&&b.name&&"FIELDSET"!==b.tagName&&!ua.test(b.type)&&(!va.test(b.type)||b.checked)){var c=W(b);void 0!==c&&(c=E(c)?c:[c],A(c,function(c){var d=a;c="&"+encodeURIComponent(b.name)+"="+encodeURIComponent(c).replace(ta,"+");a=d+c}))}})});return a.substr(1)};
y.val=function(a){return void 0===a?this[0]&&W(this[0]):this.each(function(b,c){b=V.test(c.type);var d=null===a?b?[]:"":a;b&&E(d)?A(c.options,function(a){a.selected=0<=d.indexOf(a.value)}):c.value=d})};y.clone=function(){return this.map(function(a,b){return b.cloneNode(!0)})};y.detach=function(){return this.each(function(a,b){b.parentNode&&b.parentNode.removeChild(b)})};var wa=/^\s*<(\w+)[^>]*>/,xa=/^\s*<(\w+)\s*\/?>(?:<\/\1>)?\s*$/,X;
function v(a){if(!X){var b=e.createElement("table"),c=e.createElement("tr");X={"*":e.createElement("div"),tr:e.createElement("tbody"),td:c,th:c,thead:b,tbody:b,tfoot:b}}if(!u(a))return[];if(xa.test(a))return[e.createElement(RegExp.$1)];b=wa.test(a)&&RegExp.$1;b=X[b]||X["*"];b.innerHTML=a;return x(b.childNodes).detach().get()}x.parseHTML=v;y.empty=function(){var a=this[0];if(a)for(;a.firstChild;)a.removeChild(a.firstChild);return this};
function Y(a,b,c){if(void 0!==b){var d=u(b);!d&&b.length?A(b,function(b){return Y(a,b,c)}):A(a,d?function(a){a.insertAdjacentHTML(c?"afterbegin":"beforeend",b)}:function(a,d){d=d?b.cloneNode(!0):b;c?a.insertBefore(d,a.childNodes[0]):a.appendChild(d)})}}y.append=function(){var a=this;A(arguments,function(b){Y(a,b)});return this};y.appendTo=function(a){Y(x(a),this);return this};
y.html=function(a){if(void 0===a)return this[0]&&this[0].innerHTML;var b=a.nodeType?a[0].outerHTML:a;return this.each(function(a,d){d.innerHTML=b})};y.insertAfter=function(a){var b=this;x(a).each(function(a,d){var c=d.parentNode;b.each(function(b,f){c.insertBefore(a?f.cloneNode(!0):f,d.nextSibling)})});return this};y.after=function(){var a=this;A(n.apply(arguments),function(b){n.apply(x(b).slice()).insertAfter(a)});return this};
y.insertBefore=function(a){var b=this;x(a).each(function(a,d){var c=d.parentNode;b.each(function(b,f){c.insertBefore(a?f.cloneNode(!0):f,d)})});return this};y.before=function(){var a=this;A(arguments,function(b){x(b).insertBefore(a)});return this};y.prepend=function(){var a=this;A(arguments,function(b){Y(a,b,!0)});return this};y.prependTo=function(a){Y(x(a),n.apply(this.slice()),!0);return this};y.remove=function(){return this.detach().off()};
y.replaceWith=function(a){var b=this;return this.each(function(c,d){var f=d.parentNode;if(f){c=c?x(a).clone():x(a);if(!c[0])return b.remove(),!1;f.replaceChild(c[0],d);x(c[0]).after(c.slice(1))}})};y.replaceAll=function(a){x(a).replaceWith(this);return this};y.text=function(a){return void 0===a?this[0]?this[0].textContent:"":this.each(function(b,c){c.textContent=a})};var Z=e.documentElement;
y.offset=function(){var a=this[0];if(a)return a=a.getBoundingClientRect(),{top:a.top+g.pageYOffset-Z.clientTop,left:a.left+g.pageXOffset-Z.clientLeft}};y.offsetParent=function(){return x(this[0]&&this[0].offsetParent)};y.position=function(){var a=this[0];if(a)return{left:a.offsetLeft,top:a.offsetTop}};y.children=function(a){var b=[];this.each(function(a,d){m.apply(b,d.children)});b=x(I(b));return a?b.filter(function(b,d){return C(d,a)}):b};
y.contents=function(){var a=[];this.each(function(b,c){m.apply(a,"IFRAME"===c.tagName?[c.contentDocument]:c.childNodes)});return x(a.length&&I(a))};y.find=function(a){for(var b=[],c=0,d=this.length;c<d;c++){var f=r(a,this[c]);f.length&&m.apply(b,f)}return x(b.length&&I(b))};y.has=function(a){var b=u(a)?function(b,d){return!!r(a,d).length}:function(b,d){return d.contains(a)};return this.filter(b)};
y.is=function(a){if(!a||!this[0])return!1;var b=F(a),c=!1;this.each(function(d,f){c=b(d,f,a);return!c});return c};y.next=function(){return x(this[0]&&this[0].nextElementSibling)};y.not=function(a){if(!a||!this[0])return this;var b=F(a);return this.filter(function(c,d){return!b(c,d,a)})};y.parent=function(){var a=[];this.each(function(b,c){c&&c.parentNode&&a.push(c.parentNode)});return x(I(a))};y.index=function(a){var b=a?x(a)[0]:this[0];a=a?this:x(b).parent().children();return aa.call(a,b)};
y.closest=function(a){return a&&this[0]?this.is(a)?this.filter(a):this.parent().closest(a):x()};y.parents=function(a){var b=[],c;this.each(function(d,f){for(c=f;c&&c.parentNode&&c!==e.body.parentNode;)c=c.parentNode,(!a||a&&C(c,a))&&b.push(c)});return x(I(b))};y.prev=function(){return x(this[0]&&this[0].previousElementSibling)};y.siblings=function(){var a=this[0];return this.parent().children().filter(function(b,c){return c!==a})};
})();